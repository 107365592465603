import React from 'react';
import './UpdateSubscription.scss';

function UpdateSubscription() {
  return (
    <div>
      
    </div>
  );
}

export default UpdateSubscription;
