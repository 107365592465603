import React, { useState } from 'react';
import './App.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ScriptureReader from './components/ScriptureReader/ScriptureReader';
import { useLocalStorage } from './services/useLocalStorageHook';
import { FacebookProvider } from "react-facebook";
import { Container } from 'react-bootstrap';
import Box from "./components/Box/Box";
import Welcome from "./components/Welcome/Welcome";
import registerIcons from './services/registerIcons';
import Unsubscribe from './components/Subscription/Unsubscribe/Unsubscribe';
import UpdateSubscription from './components/Subscription/UpdateSubscription/UpdateSubscription';
import Privacy from './components/Privacy/Privacy';
import VerifySubscription from './components/Subscription/VerifySubscription/VerifySubscription';
import { Route, Switch } from "react-router-dom";

registerIcons();

const App: React.FC = () => {
  const [seenWelcome, setSeenWelcome] = useLocalStorage<boolean>('seenWelcome', false);
  const [showHelp, setShowHelp] = useState(false);
  const [showScheduleSelector, setShowScheduleSelector] = useState(false);


  function onHideHelp() {
    setSeenWelcome(true);
    setShowHelp(false);
  }

  const showSchedule = () => {
    onHideHelp();
    setShowScheduleSelector(true);
  };

  return (
    <div>
      <FacebookProvider appId="1059810944062607" version="v2.5" xfbml={true}>
        <Header seenWelcome={seenWelcome} showHelp={showHelp} onShowHelp={() => setShowHelp(true)} />
        <Container fluid={true} className="main">
          <Container>
          <Switch>
            <Route path="/read-the/:volume/in/:days/days/starting/:startDate/(on)?/:currentDate?">
              {(!seenWelcome || showHelp) && <Box>
                <Welcome onHideMessage={onHideHelp} onShowSchedule={showSchedule} />
              </Box>}
              <ScriptureReader onHideHelp={onHideHelp} showScheduleSelector={showScheduleSelector} onShowScheduleSelector={showSchedule} onHideScheduleSelector={() => {setShowScheduleSelector(false)}} />
            </Route>
            <Route path="/unsubscribe/:destination">
              <Unsubscribe />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/verify/:verificationToken">
              <VerifySubscription />
            </Route>
            <Route path="/subscription/update/:userId/:newVolume">
              <UpdateSubscription />
            </Route>
            <Route path="/">
              {(!seenWelcome || showHelp) && <Box>
                <Welcome onHideMessage={onHideHelp} onShowSchedule={showSchedule} />
              </Box>}
              <ScriptureReader onHideHelp={onHideHelp} showScheduleSelector={showScheduleSelector} onShowScheduleSelector={showSchedule} onHideScheduleSelector={() => {setShowScheduleSelector(false)}} />
            </Route>
          </Switch>
          </Container>
        </Container>
        <Footer />
      </FacebookProvider>
    </div>
  );
}

export default App;
