import React from 'react';
import './Box.scss';

interface IBoxProps {
  className?: string;
}

const Box: React.FC<IBoxProps> = ({ children, className: propsClassName }) => {
  
  const className = `${propsClassName} box`;

  return (
    <div className={className}>
      {children}
    </div>
  );
}

export default Box;
