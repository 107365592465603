import React from 'react';
import './BookReader.scss';
import { Book, Schedule } from "../../models/Models";
import ChapterReader from "../ChapterReader/ChapterReader";

interface IBookReaderProps {
  book: Book
  schedule: Schedule
}

const BookReader: React.FC<IBookReaderProps> = ({ book, schedule }) => {
  const chapters = book.chapters.map((c) => <ChapterReader key={c.id} book={book} chapter={c} schedule={schedule} />)
  return (
    <li className="book-reader">
      <ul className="chapters">
        {chapters}
      </ul>
    </li>
  );
}

export default BookReader;
