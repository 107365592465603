import React from 'react';
import { Book, Chapter, Schedule } from '../../models/Models';
import './ChapterReader.scss';
import VerseReader from '../VerseReader/VerseReader';

interface IChapterReaderProps {
  book: Book;
  chapter: Chapter;
  schedule: Schedule;
}

const ChapterReader: React.FC<IChapterReaderProps> = ({ book, chapter, schedule }) => {
  const verses = chapter.verses.map(v => <VerseReader key={v.id} verse={v} />)
  const chapterOrSection = schedule.volume === 'doctrine-and-covenants' ? 'Section' : 'Chapter';
  return (
    <li className="chapter-reader chapter">
      <div className="row">
        <div className="book-title-area">
          <h2 className="book-title">{book.longTitle}</h2>
          <h3 className="book-subtitle">{book.subtitle}</h3>
        </div>
        <div className="chapter-title-area">
          <h4 className="chapter-title">{chapterOrSection}&nbsp;{chapter.number}</h4>
        </div>
      </div>
      {verses}
    </li>
  );
}

export default ChapterReader;
