import React, { useState } from 'react';
import './ScheduleSelector.scss';
import DatePicker from "react-datepicker";
import { Schedule } from "../../models/Models";
import { DateTime } from 'luxon';

import "react-datepicker/dist/react-datepicker.css";

interface IScheduleSelectorProps {
  onCloseScheduleSelector: () => void;
  onScheduleChanged: (schedule: Schedule) => void;
  schedule: Schedule;
}

const ScheduleSelector: React.FC<IScheduleSelectorProps> = ({ onCloseScheduleSelector, schedule, onScheduleChanged }) => {
  const [volume, setVolume] = useState(schedule.volume || 'book-of-mormon');
  const [startDate, setStartDate] = useState(schedule.startDate.toJSDate());
  const [endDate, setEndDate] = useState(schedule.endDate.toJSDate());

  const closeScheduleSelector = () => {
    onCloseScheduleSelector();
  }

  const changeSchedule = () => {
    onScheduleChanged({
      volume,
      startDate: DateTime.fromJSDate(startDate),
      endDate: DateTime.fromJSDate(endDate)
    })
  };

  return (
    <div id="settingsBox" className="settings">
      <h2>Settings</h2>
      <h3>Set up your schedule</h3>
      <div>
        <p>Which volume of scripture would you like to read?</p>
        <ul className="volume-list list-unstyled">
          <li>
            <label>
              <input type="radio"
                value="old-testament"
                checked={volume === "old-testament"}
                onChange={() => setVolume("old-testament")}
                 />
              <span>The Holy Bible - Old Testament</span>
            </label>
          </li>
          <li>
            <label>
              <input type="radio"
                value="new-testament"
                checked={volume === "new-testament"}
                onChange={() => setVolume("new-testament")} />
              <span>The Holy Bible - New Testament</span>
            </label>
          </li>
          <li>
            <label>
              <input type="radio"
                value="book-of-mormon"
                checked={volume === "book-of-mormon"}
                onChange={() => setVolume("book-of-mormon")} />
              <span>The Book of Mormon</span>
            </label>
          </li>
          <li>
            <label>
              <input type="radio"
                value="doctrine-and-covenants"
                checked={volume === "doctrine-and-covenants"}
                onChange={() => setVolume("doctrine-and-covenants")} />
              <span>The Doctrine &amp; Covenants</span>
            </label>
          </li>
          <li>
            <label>
              <input type="radio"
                value="pearl-of-great-price"
                checked={volume === "pearl-of-great-price"}
                onChange={() => setVolume("pearl-of-great-price")} />
              <span>The Pearl of Great Price</span>
            </label>

          </li>
        </ul>
      </div>
      <div className="calendars row">
        <div className="col-xs-12 col-lg-6">
          <p>When would you like to start?</p>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date as Date)}
            dateFormatCalendar={"MMM yyyy"}
            startDate={startDate}
            todayButton="Today"
            selectsStart
            inline
          />
        </div>
        <div className="col-xs-12 col-lg-6">
          <p>When would you like to end?</p>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date as Date)}
            dateFormatCalendar={"MMM yyyy"}
            endDate={endDate}
            minDate={startDate}
            todayButton="Today"
            selectsEnd
            inline
          />
        </div>
      </div>
      <button className="full-width button button-primary" onClick={changeSchedule}>Go to your new schedule</button>
      <button className="full-width button button-secondary"  onClick={closeScheduleSelector}>Close</button>
    </div>
  );
}

export default ScheduleSelector;
