import { FC, useState } from 'react';
import { Alert } from 'react-bootstrap';
import './LoadedCustomScheduleAlert.scss';

interface LoadedCustomScheduleAlertProps {
  onResetSchedule: () => void;
}

const LoadedCustomScheduleAlert: FC<LoadedCustomScheduleAlertProps> = ({ onResetSchedule }) => {
  const [showPreviouslyConfiguredAlert, setShowPreviouslyConfiguredAlert] = useState(true);

  return (
    <div className="LoadedCustomScheduleAlert" data-testid="LoadedCustomScheduleAlert">
      {showPreviouslyConfiguredAlert &&
        <Alert variant={'primary'} dismissible onClose={() => { setShowPreviouslyConfiguredAlert(false); }}>We restored your custom schedule. <button onClick={onResetSchedule} className="btn btn-link">Reset schedule</button></Alert>}
    </div>
  );
};

export default LoadedCustomScheduleAlert;
