import React from 'react';
import { DateTime } from 'luxon';
import './TodayDisplay.scss';

import openBook from '../../images/open-book.png';
import { DisplayScriptures } from '../../models/Models';

function last<T>(a: Array<T>): T { return a[a.length - 1]; }

const getDisplayCurrentDate = (currentDate: DateTime, today: DateTime) => {
  if (currentDate.startOf('day').diff(today.startOf('day'), 'days').days === 0) {
    return 'today';
  }
  return currentDate.toLocaleString(DateTime.DATE_MED);
}

const getDisplayStartVerse = function (scriptures: DisplayScriptures) {
  var book = scriptures![0].books[0];
  var chapter = book.chapters[0];
  var verse = chapter.verses[0];
  return book.shortTitle + '&nbsp;' + chapter.number + ':' + verse.number;
};

const getDisplayEndVerse = function (scriptures: DisplayScriptures) {
  var book = last(last(scriptures!).books);
  var chapter = last(book.chapters);
  var verse = last(chapter.verses);
  return book.shortTitle + '&nbsp;' + chapter.number + ':' + verse.number;
};

const areScripturesLoaded = (scriptures: DisplayScriptures) => {
  return scriptures &&
    scriptures.length > 0 &&
    scriptures[0];
};

const TodayDisplay = ({currentDate, today, scriptures}: {currentDate: DateTime, today: DateTime, scriptures: DisplayScriptures}) => {
  if (!areScripturesLoaded(scriptures)) return null;

  const displayCurrentDate = getDisplayCurrentDate(currentDate, today);
  const displayStartVerse = getDisplayStartVerse(scriptures);
  const displayEndVerse = getDisplayEndVerse(scriptures);

  return (
    <div className="TodayDisplay" data-testid="TodayDisplay">
      <div className="today">
        <img src={openBook} alt="" />
        <h4>Reading assignment for {displayCurrentDate}</h4>
        <p><span dangerouslySetInnerHTML={{ __html: displayStartVerse }} /> - <span dangerouslySetInnerHTML={{ __html: displayEndVerse }} /></p>
      </div>
    </div>
  );
};

export default TodayDisplay;
