import React from 'react';
import './VerifySubscription.scss';

function VerifySubscription() {
  return (
    <div>
      
    </div>
  );
}

export default VerifySubscription;
