import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './NextPreviousButtons.scss';

const NextPreviousButtons = ({onGoToYesterday, onGoToTomorrow}: {onGoToYesterday: () => void, onGoToTomorrow: () => void}) => (
  <div className="NextPreviousButtons" data-testid="NextPreviousButtons">
    <div className="scriptures-next row">
      <div className="yesterday-reading">
        <p><button className="button" onClick={onGoToYesterday}><FontAwesomeIcon icon="long-arrow-alt-left" /><span className="hidden-default visible-phone">&nbsp; Yesterday</span><span className="hidden-default visible-phablet">'s reading</span></button></p>
      </div>
      <div>&nbsp;</div>
      <div className="tomorrow-reading">
        <p><button className="button" onClick={onGoToTomorrow}><span className="hidden-default visible-phone">Tomorrow<span className="hidden-default visible-phablet">'s reading&nbsp;</span></span><FontAwesomeIcon icon="long-arrow-alt-right" /></button></p>
      </div>
    </div>
  </div>
);

export default NextPreviousButtons;
