var en = require("nanoid-good/locale/en");
var nanoid = require("nanoid-good").nanoid(en);

export const getUserId = () => {
    const key = 'userId';
    const generateAndStoreId = () => {
        const id = nanoid();
        window.localStorage.setItem(key, id);
        return id;
    }
    return window.localStorage.getItem(key) || generateAndStoreId();
} 
