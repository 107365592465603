import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisplayScriptures, Schedule } from '../../models/Models';
import { getPercentComplete } from "../../services/scheduleService";
import { getVolumeDisplayName } from "../../services/scripturesService";

import './ScheduleDisplay.scss';
import redBookmark from '../../images/red_bookmark.png';
import { DateTime } from 'luxon';

const ScheduleDisplay = ({schedule, scriptures, currentDate, onEditSchedule}: {schedule: Schedule, scriptures: DisplayScriptures, currentDate: DateTime, onEditSchedule: () => void}) => {
  const displayCurrentVolume = getVolumeDisplayName(schedule.volume);
  const displayCurrentVolumeSubtitle = scriptures && scriptures.length ? scriptures[0].subtitle : '';
  const displayStartDate = schedule.startDate.toLocaleString(DateTime.DATE_MED);
  const displayEndDate = schedule.endDate.toLocaleString(DateTime.DATE_MED);
  const displayProgress = getPercentComplete(schedule, currentDate);

  return (
    <div className="ScheduleDisplay" data-testid="ScheduleDisplay">
      <img className="red-bookmark" src={redBookmark} alt="" />
      <h2 className="volume-title">{displayCurrentVolume}</h2>
      <h3 className="volume-subtitle">{displayCurrentVolumeSubtitle}</h3>
      <p><FontAwesomeIcon icon={["far", "calendar-alt"]} />&nbsp; <span dangerouslySetInnerHTML={{__html: displayStartDate}} /> - <span dangerouslySetInnerHTML={{__html: displayEndDate}} /></p>
      <p><FontAwesomeIcon icon="hourglass-half" />&nbsp; {displayProgress}% complete (after today's reading)</p>
      <p className="schedule-link"><button onClick={onEditSchedule}><FontAwesomeIcon icon="edit" />&nbsp; Edit my schedule</button></p>
    </div>
  )
};

export default ScheduleDisplay;
