import React from 'react';
import './Unsubscribe.scss';

function Unsubscribe() {
  return (
    <div>
      
    </div>
  );
}

export default Unsubscribe;
