import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Verse } from '../../models/Models';
import * as loveService from '../../services/loveService';
import './VerseReader.scss';

interface IVerseReaderProps {
  verse: Verse,
}

const VerseReader = ({ verse }: IVerseReaderProps) => {
  const [isLovedByMe, setIsLovedByMe] = useState(false);
  const [numLoves, setNumLoves] = useState((loveService.numberOfServerLoves && loveService.numberOfServerLoves[verse.id]) || 0);

  useEffect(() => {
    const fetch = async () => {
      const isLoved = await loveService.verseIsLovedByMe(verse.id);
      setIsLovedByMe(isLoved);
    }
    fetch();
  }, [verse])

  const loveVerse = async () => {
    setIsLovedByMe(true);
    setNumLoves((old) => old + 1);
    await loveService.loveVerse(verse.id);
  }
  const unloveVerse = async () => {
    setIsLovedByMe(false);
    setNumLoves((old) => Math.max(old - 1, 0));
    await loveService.unloveVerse(verse.id);
  }

  const isLovedByAnyone = numLoves > 0;
  const verseLoveIconPrefix = isLovedByMe ? 'fas' : 'far';
  const verseLoveIconAction = isLovedByMe ? unloveVerse : loveVerse;

  return (
    <div className="verse-reader verses">
      <div className="row">
        <div className="verse">
        {<span className="love"><button onClick={verseLoveIconAction}><FontAwesomeIcon icon={[verseLoveIconPrefix, "heart"]} /></button></span>}
        {isLovedByAnyone && <span className="numLoves">{numLoves}</span>}
        <span className="versenum">{verse.number}</span>&nbsp;{verse.text}
        </div>
      </div>
    </div>
  );
}

export default VerseReader;
