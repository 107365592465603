import axios from 'axios';
import localforage from 'localforage';
import { getUserId } from "./userService";

const loveStorageKey = 'lovedVerses';

export let numberOfServerLoves: { [verseId: number]: number} = { 32037: 3, 32038: 1, 32039: 0 };

// Local
const loveVerseLocally = async function (verseId: number) {
    let localVerses = await localforage.getItem<Array<number>>(loveStorageKey);
    if (typeof localVerses === 'undefined' || !Array.isArray(localVerses)) {
        localVerses = [verseId];
    }
    if (localVerses.indexOf(verseId) === -1) {
        localVerses.push(verseId);
    }
    localforage.setItem(loveStorageKey, localVerses);
    return true;
};
const unloveVerseLocally = async function (verseId: number) {
    let localVerses = await localforage.getItem<Array<number>>(loveStorageKey);
    if (typeof localVerses !== 'undefined' && Array.isArray(localVerses)) {
        const filteredVerses = localVerses.filter(function (item) {
            return item !== verseId;
        });
        localforage.setItem(loveStorageKey, filteredVerses);
    }
};
const isLovedByMeLocally = async function (verseId: number) {
    let localVerses = await localforage.getItem<Array<number>>(loveStorageKey);
    // console.log('checking verse', verseId, localVerses);
    if (typeof localVerses === 'undefined' || !Array.isArray(localVerses)) {
        return false;
    }
    return (localVerses.indexOf(verseId) !== -1);
};

// Remote
const loveVerseRemotely = function (verseId: number) {
    return axios({
        method: 'PUT',
        url: process.env.REACT_APP_API_LOVES + '/loves',
        data: {
            user: { id: getUserId() },
            verseId: verseId,
            action: 'love'
        }
    }).then(function (response) {
        if (response.data.status === 'success') {
            console.info("Successfully loved verse " + verseId);
        }
    }, function (errorResponse) {
        console.error(errorResponse);
    });
};
const unloveVerseRemotely = function (verseId: number) {
    return axios({
        method: 'PUT',
        url: process.env.REACT_APP_API_LOVES + '/loves',
        data: {
            user: { id: getUserId() },
            verseId: verseId,
            action: 'unlove'
        }
    }).then(function (response) {
        if (response.data.status === 'success') {
            console.info("Successfully unloved verse " + verseId);
        }
    }, function (errorResponse) {
        console.error(errorResponse);
    });
};

export const refreshLoves = function (startVerseId: number, endVerseId: number) {
    if ((startVerseId && !isFinite(startVerseId)) || (endVerseId && !isFinite(endVerseId))) {
        console.error("refreshLoves: Invalid start and end verse ids passed");
        return false;
    }
    return axios({
        method: 'GET',
        url: process.env.REACT_APP_API_LOVES + '/loves',
        params: {
            user: { id: getUserId() },
            startVerseId: startVerseId,
            endVerseId: endVerseId
        }
    }).then(function (response) {
        const numLovesRetrieved = Object.keys(response.data.loves).length;
        const numOfVersesRequested = endVerseId - startVerseId + 1; // include the last verse
        if (numLovesRetrieved !== numOfVersesRequested) {
            console.error("Requested loves for " + numOfVersesRequested + " verses but got loves for " + numLovesRetrieved + " verses.");
        }
        numberOfServerLoves = response.data.loves;
    }, function (errorResponse) {
        console.error(errorResponse);
    });
};

export const loveVerse = async (verseId: number) => {
    if (!isFinite(verseId) || verseId <= 0) {
        return false;
    }
    await loveVerseLocally(verseId);
    return loveVerseRemotely(verseId);
}
export const unloveVerse = async (verseId: number) => {
    if (!isFinite(verseId) || verseId <= 0) {
        return false;
    }
    await unloveVerseLocally(verseId);
    return unloveVerseRemotely(verseId);
}
export const verseIsLovedByMe = async (verseId: number) => {
    return await isLovedByMeLocally(verseId);
}
