import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faQuestionCircle,
    faLongArrowAltRight,
    faLongArrowAltLeft,
    faHeart as fasHeart,
    faHourglassHalf,
    faEdit
} from '@fortawesome/free-solid-svg-icons';
import {
    faHeart as farHeart,
    faCalendarAlt
} from '@fortawesome/free-regular-svg-icons';

export default function registerIcons() {
    library.add(
        faQuestionCircle,
        faLongArrowAltRight,
        faLongArrowAltLeft,
        farHeart,
        fasHeart,
        faCalendarAlt,
        faHourglassHalf,
        faEdit
    );
}