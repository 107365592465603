import React, { useEffect, useState } from 'react';
import './Scriptures.scss';
import '../../styles/spinner.scss';
import { DisplayScriptures, Schedule } from '../../models/Models';
import Loading from '../Loading/Loading';
import { Alert } from 'react-bootstrap';
import { DateTime, Duration } from 'luxon';
import VolumeReader from '../VolumeReader/VolumeReader'
import ScheduleDisplay from '../ScheduleDisplay/ScheduleDisplay';
import ScheduleEndedDisplay from '../ScheduleEndedDisplay/ScheduleEndedDisplay';
import ScheduleNotStartedDisplay from '../ScheduleNotStartedDisplay/ScheduleNotStartedDisplay';
import NextPreviousButtons from '../NextPreviousButtons/NextPreviousButtons';
import TodayDisplay from '../TodayDisplay/TodayDisplay';
import { getEndVerseId, getScriptures, getStartVerseId } from "../../services/scripturesService";
import { isAfterScheduleEnds, isBeforeScheduleStarts } from '../../services/scheduleService';
import { getURL } from "../../services/urlService";
import { refreshLoves } from '../../services/loveService';
import { useHistory } from 'react-router-dom';

interface IScripturesProps {
  schedule: Schedule,
  today: DateTime,
  userRequestedDate?: DateTime,
  onShowSchedule: () => void
}

const Scriptures: React.FC<IScripturesProps> = ({ schedule, userRequestedDate, today, onShowSchedule }) => {
  const [loading, setLoading] = useState(true);
  const [scriptures, setScriptures] = useState<DisplayScriptures | null>([]);
  const [isError, setIsError] = useState(false);
  const [isScheduleEnded, setIsScheduleEnded] = useState(false);
  const [isScheduleStarted, setIsScheduleStarted] = useState(true);
  const [currentDate, setCurrentDate] = useState(userRequestedDate || today);
  const [currentDateISO, setCurrentDateISO] = useState(currentDate.toISO());

  const history = useHistory();

  useEffect(() => {
    setCurrentDate(userRequestedDate || today);
  }, [userRequestedDate, today])

  useEffect(() => {
    // Order is important in these useEffect calls. The ISO var must be set
    // before its dependency is checked in the useEffect to reload data
    setCurrentDateISO(currentDate.toISO());
  }, [currentDate]);

  useEffect(() => {
    const fetchData = async () => {
      // This whole "use the ISO instead of the object" strategy is to get around this useEffect being triggered every time the
      // currentDate object changes, which will happen frequently (as it has resolution to the millisecond/second, 
      // so every time react check it will register as different)
      const date = DateTime.fromISO(currentDateISO);
      setIsError(false);
      setLoading(true);
      try {
        const scriptureResponse = await getScriptures(date, schedule);
        if (scriptureResponse.status === 200) {
          setScriptures(scriptureResponse.data);
          if (scriptureResponse.data) {
            await refreshLoves(getStartVerseId(scriptureResponse.data), getEndVerseId(scriptureResponse.data));
          }
        }
      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        setLoading(false);
        setIsScheduleStarted(!isBeforeScheduleStarts(schedule, date))
        setIsScheduleEnded(isAfterScheduleEnds(schedule, date));
      }
    }

    fetchData();
  }, [schedule, currentDateISO, history]);

  const areScripturesLoaded = () => {
    return scriptures &&
      scriptures.length > 0 &&
      scriptures[0];
  };

  const goToYesterday = () => {
    const newDate = currentDate.minus(Duration.fromObject({ days: 1 }));
    history.push(getURL(schedule, newDate, today));
  };

  const goToTomorrow = () => {
    const newDate = currentDate.plus(Duration.fromObject({ days: 1 }));
    history.push(getURL(schedule, newDate, today));
  };

  const volumes = (areScripturesLoaded() && scriptures!.map((v) => <VolumeReader key={v.id} volume={v} schedule={schedule} />));

  return (
    <div className="scriptures">
      {isError && <Alert color="danger">There was an error while trying to retrieve the scripture reading.</Alert>}
      {loading && <Loading />}
      {!loading && !isError && <div className="narrow">
        {areScripturesLoaded() && <ScheduleDisplay schedule={schedule} scriptures={scriptures!} currentDate={currentDate} onEditSchedule={onShowSchedule} />}
        {isScheduleEnded && <ScheduleEndedDisplay schedule={schedule} onEditSchedule={onShowSchedule} />}
        {!isScheduleStarted && <ScheduleNotStartedDisplay schedule={schedule} onEditSchedule={onShowSchedule} />}
        {!isScheduleEnded && <div>
          {areScripturesLoaded() && <TodayDisplay currentDate={currentDate} today={today} scriptures={scriptures!} />}
          <NextPreviousButtons onGoToYesterday={goToYesterday} onGoToTomorrow={goToTomorrow} />
          {volumes}
          <NextPreviousButtons onGoToYesterday={goToYesterday} onGoToTomorrow={goToTomorrow} />
        </div>
        }
      </div>
      }
    </div>
  );
}

export default Scriptures;
