import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ScheduleNotStartedDisplay.scss';
import { Schedule } from '../../models/Models';
import { DateTime } from 'luxon';

const ScheduleNotStartedDisplay = ({schedule, onEditSchedule}: {schedule: Schedule, onEditSchedule: () => void}) => {
  const displayStartDate = schedule.startDate.toLocaleString(DateTime.DATE_MED);

  return (
    <div className="ScheduleNotStartedDisplay" data-testid="ScheduleNotStartedDisplay">
      <h2>Here's a sneak peek</h2>
      <p>Your schedule hasn't started yet (you've configured your reading assignments to start on <span dangerouslySetInnerHTML={{__html:displayStartDate}} />).
        In the meantime, enjoy a sneak peek of the first day's reading. Or, you can adjust your schedule to start today.</p>
      <p className="schedule-link"><button onClick={onEditSchedule}><FontAwesomeIcon icon="edit" />&nbsp; Edit my schedule</button></p>
      <hr />
    </div>
  )
};

export default ScheduleNotStartedDisplay;
