import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Schedule } from '../../models/Models';
import { getVolumeDisplayName } from "../../services/scripturesService";

import './ScheduleEndedDisplay.scss';

const ScheduleEndedDisplay = ({schedule, onEditSchedule}: {schedule: Schedule, onEditSchedule: () => void}) => {
  const displayCurrentVolume = getVolumeDisplayName(schedule.volume);

  return (
    <div className="ScheduleEndedDisplay" data-testid="ScheduleEndedDisplay">
      <h2>Congratulations!</h2>
      <p>You've finished reading {displayCurrentVolume}. To keep reading your Scriptures Every Day, please choose a new
        volume or a new date range.</p>
      <p className="schedule-link"><button onClick={onEditSchedule}><FontAwesomeIcon icon="edit" />&nbsp; Edit my schedule</button></p>
    </div>
  )
};

export default ScheduleEndedDisplay;
