import { DateTime } from 'luxon';
import { Schedule, VolumeName } from '../models/Models';

const yearMapping: { [index: number]: VolumeName } = {
    0: 'doctrine-and-covenants',
    1: 'old-testament',
    2: 'new-testament',
    3: 'book-of-mormon'
};

/**
 * Creates a schedule object from route parameters.
 * 
 * @param params Route parameters
 * @returns A schedule
 */
export const getScheduleFromParams = (params: { volume: string, days: string, startDate: string }): Schedule | null => {
    if (!params.volume || !params.days || !params.startDate) {
        return null;
    }

    switch (params.volume) {
        case 'old-testament':
        case 'new-testament':
        case 'book-of-mormon':
        case 'doctrine-and-covenants':
        case 'pearl-of-great-price':
            break;
        default:
            console.error(`Unrecognized volume: ${params.volume}`);
            return null;
    }

    const days = parseInt(params.days);
    if (isNaN(days)) {
        return null;
    }

    const startDate = DateTime.fromISO(params.startDate);
    if (!startDate.isValid) {
        return null;
    }

    return {
        volume: params.volume,
        startDate: startDate,
        endDate: startDate.plus({ days: days - 1 })
    };
}

/**
 * Returns a schedule object loaded from the browser's local storage.
 * 
 * @param key The string key to use for storing in local storage
 * @returns The loaded schedule or null if it can't be found or parsed
 */
export const getScheduleFromLocalStorage = (key: string) => {
    const scheduleJSON = window.localStorage.getItem(key);
    if (!scheduleJSON) {
        return null;
    }

    try {
        const schedule = JSON.parse(scheduleJSON) as Schedule;
        // Parse the stored JSON ISOs into valid DateTime objects
        schedule.startDate = DateTime.fromISO(schedule.startDate as unknown as string);
        schedule.endDate = DateTime.fromISO(schedule.endDate as unknown as string);

        return schedule;
    } catch (e) {
        console.error('Could not parse schedule from local storage.', scheduleJSON);
        window.localStorage.removeItem(key);
        return null;
    }
}

/**
 * Stores a serialized schedule into local storage.
 * 
 * @param key The string key to use for storing in local storage
 * @param schedule The schedule to store (will be serialized first)
 */
export const storeScheduleToLocalStorage = (key: string, schedule: Schedule) => {
    const scheduleToStore = {
        volume: schedule.volume,
        startDate: schedule.startDate.toISODate(),
        endDate: schedule.endDate.toISODate()
    };
    
    window.localStorage.setItem(key, JSON.stringify(scheduleToStore));
}

/**
 * Creates a default schedule based on the rotating 4-year volume selection.
 * 
 * @param now The "current" date
 * @returns A default schedule
 */
export const getDefaultSchedule = (now: DateTime) => {
    var defaultSchedule: Schedule = {
        startDate: now.startOf('year'),
        volume: 'book-of-mormon',
        endDate: now.endOf('year').startOf('day')
    };
    var yearCode = (now.year + 3) % 4;
    defaultSchedule.volume = yearMapping[yearCode];
    return defaultSchedule;
};

/**
 * Returns percent complete of a schedule against a measurement date
 * @param schedule 
 * @param currentDate 
 * @returns Percent complete
 */
export const getPercentComplete = (schedule: Schedule, currentDate: DateTime) => {
    var daysElapsed = currentDate.startOf('day').diff(schedule.startDate.startOf('day'), 'days').days + 1; // Since you don't start on day 0, day 1 is day 1
    console.log('daysElapsed', daysElapsed);
    var daysTotal = getTotalDays(schedule);
    console.log('daystotal', daysTotal);
    var percent = Math.ceil((daysElapsed / daysTotal) * 100);
    console.log('rawpercent', percent);
    percent = Math.min(100, percent);
    percent = Math.max(0, percent);
    return percent;
};

export const isAfterScheduleEnds = (schedule: Schedule, currentDate: DateTime) => {
    return currentDate.startOf('day').diff(schedule.endDate.startOf('day'), 'days').days > 0;
};

export const isBeforeScheduleStarts = (schedule: Schedule, currentDate: DateTime) => {
    return currentDate.startOf('day').diff(schedule.startDate.startOf('day'), 'days').days < 0;
};

/**
 * Returns the total number of days for a schedule
 */
export const getTotalDays = (schedule: Schedule) => {
    return schedule.endDate.diff(schedule.startDate, 'days').days + 1 // plus one since the end date of a schedule is inclusive
}