import React from 'react';
import './Welcome.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';

interface IWelcomeProps {
  onHideMessage: () => void;
  onShowSchedule: () => void;
}

const Welcome: React.FC<IWelcomeProps> = ({ onHideMessage, onShowSchedule }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="welcome">
      <h1>LDS Scriptures Every Day</h1>
      <h2>Visit daily to see your reading.</h2>
      <p>Read the Bible, Book of Mormon, or any of the other standard works on your own schedule. Or, by default, read the Book
        of Mormon during {currentYear}.</p>
      <Row>
        <Col xs="12" sm="6">
          <button className="button button-primary" onClick={onShowSchedule}>Set my own schedule <FontAwesomeIcon icon="long-arrow-alt-right" /></button>
        </Col>
        <Col xs="12" sm="6">
          <button className="button button-secondary" onClick={onHideMessage}>Hide this message</button>
        </Col>
      </Row>
    </div>
  );
}

export default Welcome;
