import React from 'react';
import './Loading.scss';
import '../../styles/spinner.scss';

const Loading: React.FC = () => {

  return (
    <div className="loading">
      <h2>Preparing your daily feast...</h2>
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
    </div>
  );
}

export default Loading;
