import { DateTime } from "luxon";
import { Schedule } from "../models/Models";
import { getTotalDays } from './scheduleService';

/**
 * Gets a relative URL for a current schedule and date
 * @param s Schedule object
 * @param cd The "as-of" requested date/time
 * @param t Today's date/time (used to see if we need to display the "as-of" requested date/time in the URL)
 * @returns A string url
 */
export const getURL = (s: Schedule, cd: DateTime, t: DateTime) => {
    let url = `/read-the/${s.volume}/in/${getTotalDays(s)}/days/starting/${s.startDate.toISODate()}`;
    if (cd && t.startOf('day').diff(cd.startOf('day'), 'days').days !== 0) {
      url += `/on/${cd.toISODate()}`;
    }
    return url;
  }