import React from 'react';
import { Schedule, Volume } from '../../models/Models';
import './VolumeReader.scss';
import BookReader from '../BookReader/BookReader';

interface IVolumeProps {
    volume: Volume
    schedule: Schedule
}

const VolumeReader: React.FC<IVolumeProps> = ({ volume, schedule }) => {
    const books = volume.books.map((b) => <BookReader key={b.id} book={b} schedule={schedule} />);

    return (
        <div className="volume" data-testid="volume-reader">
            {/* <h2 className="volume-title">{{ volume.longTitle }}</h2> */}
            {/* <h3 className="volume-subtitle vertical-align-center">{{ volume.subtitle }}</h3> */}
            <ul className="books">
                {books}
            </ul>
        </div>
    );
}

export default VolumeReader;
