import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Header.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Like } from 'react-facebook';

import logo from '../../images/SE_Logo_Light.png';

interface IHeaderProps {
  seenWelcome: boolean;
  onShowHelp: () => void;
  showHelp: boolean;
}
const Header: React.FC<IHeaderProps> = ({ seenWelcome, onShowHelp, showHelp }) => {
  return (
    <div>
      <header>
        <Container>
          <Row>
            <Col xs="8" md="6">
              <a href="/"><img className="logo" src={logo} alt="Scriptures Every Day" /></a>
            </Col>
            <Col xs="4" md="2" className="order-md-2">
              <div className="flex help-button">
                {(seenWelcome && !showHelp) && <button className="non-button" onClick={onShowHelp}>
                  <h5>Help&nbsp;<FontAwesomeIcon icon="question-circle" /></h5>
                </button>}
              </div>
            </Col>
            <Col xs="12" md="4" className="order-md-1">
              <div className="flex fb-like">
              <Like href="https://www.facebook.com/scriptureseveryday" layout="button_count" action="like" showFaces={false} share={true} />
              </div>
            </Col>
          </Row>
        </Container>

      </header>
    </div>
  );
}

export default Header;
