import React, { useState } from 'react';
import './ScriptureReader.scss';
import ScheduleSelector from "../ScheduleSelector/ScheduleSelector";
import Scriptures from '../Scriptures/Scriptures';
import LoadedCustomScheduleAlert from "../LoadedCustomScheduleAlert/LoadedCustomScheduleAlert";
import { useHistory, useParams } from 'react-router-dom';
import { getURL } from '../../services/urlService';
import { DateTime } from 'luxon';
import { getDefaultSchedule, getScheduleFromLocalStorage, getScheduleFromParams, storeScheduleToLocalStorage } from '../../services/scheduleService';
import { Schedule } from '../../models/Models';
import Box from "../Box/Box";

interface IScriptureReaderProps {
  onHideHelp: () => void;
  showScheduleSelector: boolean;
  onHideScheduleSelector: () => void;
  onShowScheduleSelector: () => void;
}

const scheduleLocalStorageKey = "schedule";

const ScriptureReader: React.FC<IScriptureReaderProps> = ({ onHideHelp, showScheduleSelector, onHideScheduleSelector, onShowScheduleSelector }) => {
  const [today] = useState(DateTime.local());

  const params = useParams<{ volume: string, days: string, startDate: string, currentDate?: string }>();
  const userRequestedDate = params.currentDate ? DateTime.fromISO(params.currentDate) : today;
  const storedSchedule = getScheduleFromLocalStorage(scheduleLocalStorageKey);
  const paramSchedule = getScheduleFromParams(params);
  const loadedStoredSchedule = storedSchedule && !paramSchedule;
  const initialSchedule = paramSchedule || storedSchedule || getDefaultSchedule(today);
  const [schedule, setSchedule] = useState<Schedule>(initialSchedule);

  const history = useHistory();

  const updateUrlToNewSchedule = (schedule: Schedule) => {
    const url = getURL(schedule, userRequestedDate, today);
    history.push(url);
  }

  const onScheduleChanged = (schedule: Schedule) => {
    onHideHelp();
    onHideScheduleSelector();
    setSchedule(schedule);
    updateUrlToNewSchedule(schedule);
    storeScheduleToLocalStorage(scheduleLocalStorageKey, schedule);
  }

  const resetStoredSchedule = () => {
    onScheduleChanged(getDefaultSchedule(today));
  }

  return (
    <div className="ScriptureReader" data-testid="ScriptureReader">
      {showScheduleSelector && <Box><ScheduleSelector schedule={schedule} onCloseScheduleSelector={() => onHideScheduleSelector()} onScheduleChanged={onScheduleChanged} /></Box>}
      {loadedStoredSchedule && <LoadedCustomScheduleAlert onResetSchedule={resetStoredSchedule} />}
      <Scriptures schedule={schedule} today={today} userRequestedDate={userRequestedDate} onShowSchedule={onShowScheduleSelector} />
    </div>
  );
}

export default ScriptureReader;
